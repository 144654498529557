<template>
	<div>
		<div class="columns is-multiline toolbar mb-5">
			<div class="column is-6">
				<h4 class="is-size-4 has-text-primary">Documents files: {{  items.length }} files.</h4>
			</div>
			<div class="column is-6 has-text-right">
				<a class="button is-white list-icon" v-on:click="layout = 'list'" v-bind:class="{ 'active': layout == 'list'}" title="List">
					<i class="fa-solid is-size-3 fa-grip-lines"></i>
				</a>
				<a class="button is-white grid-icon" v-on:click="layout = 'grid'" v-bind:class="{ 'active': layout == 'grid'}" title="Grid">
					<i class="fa-solid is-size-3 fa-grip"></i>
				</a>
			</div>
		</div>

		<div class="documents" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500" infinite-scroll-distance="8">
			<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy">
				<div class="columns is-multiline list" v-if="layout == 'list'">
					<!-- A "list" view -->
					<div class="column is-12 p-2" v-for="document in items">
						<div class="card has-border radius-15 has-background-accent1 px-4">
							<a v-bind:href="document.image" class="has-text-primary is-size-4" target="_blank">
								<div class="card-content">
									<div class="media">
										<div class="media-left">
											<figure>
												<lazy-load
												src="/front/images/spinner.svg"
												lazy-src="/front/images/document-text.svg"
												lazy-srcset="/front/images/document-text.svg"
												background-color="transparent"
												:alt="document.title" class="image-contain"
												/>
											</figure>
										</div>
										<div class="media-content px-1">
											<p class="is-4 has-text-primary mt-2">{{document.title}}</p>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="columns is-multiline grid" v-if="layout == 'grid'">
					<!-- A "grid" view  -->
					<div class="column is-3" v-for="document in items">
						<div class="card has-border radius-15 has-background-accent1 has-text-centered p-3" data-aos="flip-left"
						data-aos-easing="ease-out-cubic"
						data-aos-duration="2000">
						<a v-bind:href="document.image" class="has-text-primary is-size-4" target="_blank">
							<div class="card-image mt-4">
								<figure>
									<lazy-load
									src="/front/images/spinner.svg"
									lazy-src="/front/images/document-text.svg"
									lazy-srcset="/front/images/document-text.svg"
									background-color="transparent"
									:alt="document.title" class="image-contain"
									/>
								</figure>
							</div>
							<div class="card-content">
								<p class="is-4 has-text-primary">{{document.title}}</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="has-text-centered mt-6" v-show="loadMore">
			<a rel="noreferrer" class="news-loader" href="">
				<img src="/front/images/loader.svg" alt="">
			</a>
		</div>
	</div>
</div>
</template>

<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	import LazyLoad from '../GeneralComponents/LazyLoad';
	export default {
		name: 'Documents',
		component:'LazyLoad',
		directives: {
			infiniteScroll
		},
		props: {
			data: {
				type: Array,
				default: () => []
			},
			url: String,
		},
		data() {
			return {
				layout: 'list',
				items: [],
				currentPage: 0,
				fetching: false,
				nextUrl : String,
				loadMore : true,
				isActive: false,
				documents: [{
					title: 'HSE Appreciation Certificate - Al Ain Municipality',
					url: 'https://voltagead.com/tapping-ugc-offerpop/'
				}, {
					title: '5 websites that get design right',
					url: 'https://voltagead.com/5-websites-get-design-right/'
				}, {
					title: 'Mariachis, Hats, and Pies, Oh My!',
					url: 'https://voltagead.com/mariachis-hats-pies-oh/'
				}, {
					title: 'HSE Appreciation Certificate - Al Ain Municipality.',
					url: 'https://voltagead.com/big-buzz-big-brands-reebok-omnichannel/'
				}, {
					title: 'Colorado Ad Day',
					url: 'https://voltagead.com/denver-ad-day/'
				}, {
					title: 'HSE Appreciation Certificate - Al Ain Municipality',
					url: 'https://voltagead.com/using-ordinary-build-extraordinary/'
				}]
			}
		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.items.push(...data.data);
						if(data.links.next != null) {
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
		created() {
			this.nextUrl = this.url;
			this.items = [];
			this.loadNextPage();
		}
	};
</script>

<style lang="scss">

	.toolbar .button {
		background-color: #F8F9FC;
		color: #3F4756;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin:0 .3rem;
	}

	.toolbar a.active, .toolbar a:hover, .toolbar a:focus {
		background-color: #0684F1!important;
		color: #FFF!important;
		box-shadow:(0px 15px 25px rgba(6, 132, 241, 0.30));
		transition: .4s ease-in-out;
	}

	.toolbar a.active:hover {
		cursor: default;
	}

	/* Grid layout */
	.documents{
		.card{
			box-shadow: none;
			.card-content{
				padding:1rem;
			}
			a:hover> .card-content .media .media-content .has-text-primary{
				color: #FB2A1C!important;
			}
			&:hover{
				border:2px solid #0684F1;
				box-shadow:0 1px 7px 2px rgba(0, 0, 0, 0.2);
			}
		}
		.grid{
			.card{
				.card-content{
					min-height: 7.5rem;
				}
			}
		}
	}

</style>


