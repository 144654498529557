<template>
	<div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="4">
			<single-client v-for="item,index in info" :key="index" :info="item">
				
			</single-client>
			
			<div class="has-text-centered mt-6" v-show="loadMore">
				<a rel="noreferrer" class="news-loader" href="">
					<img src="/front/images/loader.svg" alt="">
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	import LazyLoad from '../GeneralComponents/LazyLoad';
	import SingleClient from '../SiteComponents/SingleClient';
	export default {
		name: 'Clients',
		components: {
			LazyLoad ,
			SingleClient
		},
		directives: {
			infiniteScroll
		},
		props: {
			data: {
				type: Array,
				default: () => []
			},
			url: String,
		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		mounted() {
		},
		data: () => ({
			items: [],
			order : [],
			currentPage: 0,
			fetching: false,
			nextUrl : String,
			loadMore : true,
			isActive: false,
			info:[
			{
				title:'test name',
				social:'@john',
				paragraph:
				"“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec... “ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec..."
			},
			{
				title:'test name2',
				social:'@john',
				paragraph:
				"“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec... “ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec..."
			},
			{
				title:'test name',
				social:'@john',
				paragraph:
				"“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec... “ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec..."
			},
			{
				title:'test name',
				social:'@john',
				paragraph:
				"“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec... “ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec..."
			}
			]
		}),
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.items.push(...data.data);
						if(data.links.next != null) {
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
		created() {
			this.nextUrl = this.url;
			this.items = [];
			this.loadNextPage();
		}
	};
	
</script>
