window.onscroll = function() {myFunction()};

function myFunction() {
	if (window.pageYOffset >= 100) {
		document.getElementById("fixed-nav").classList.add("scroll");
	} else {
		document.getElementById("fixed-nav").classList.remove("scroll");
	}
	if (window.pageYOffset >= 2800) {
		document.getElementById("fixed-group").classList.add("scroll");
	} else {
		document.getElementById("fixed-group").classList.remove("scroll");
	}
	if (window.pageYOffset >= 2800) {
		document.getElementById("fixed-scroll-top").classList.add("scroll");
	} else {
		document.getElementById("fixed-scroll-top").classList.remove("scroll");
	}
 }
document.addEventListener('DOMContentLoaded', function () {
	// ---------- to top -----------
	 $('.scroll-top a').click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
        return false;
    });

  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
    	$el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

    });
    });
};

});