<template>
	<div class="card radius-15 has-border mb-3 has-background-accent1" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="linear">
		<div class="card-content">
			<div class="media">
				<div class="media-left">
					<figure>
						<lazy-load
						src="/front/images/spinner.svg"
						lazy-src="/front/images/client.png"
						lazy-srcset="/front/images/client.png"
						background-color="transparent"
						alt="title" class="image-cover"
						/>
					</figure>
				</div>
				<div class="media-content px-4">
					<p class="title is-4">{{info.title}}</p>
					<p class="subtitle is-6 mb-2 has-text-primary">{{info.social}}</p>
					<p class="has-text-primary">{{ displayedText }}</p>
					<div class="btn-container">
						<button class="button is-success ml-4" @click="expandText" v-if="isCollapsed">
							<i class="fa-solid is-size-6 fa-plus"></i>
						</button>
						<button class="button is-success ml-4" @click="collapseText(250)" v-else>
							<i class="fa-solid is-size-6 fa-minus"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
	import LazyLoad from '../GeneralComponents/LazyLoad';
	export default {
		name: 'Clients',
		components: {
			LazyLoad ,
		},
		props: {
			info: {
				type: Object,
				
			}
		},
		computed: {
			
		},
		mounted() {
			this.collapseText(250);
		},
		data: () => ({
			displayedText: "",
			isCollapsed: false,
		}),
		methods: {
			collapseText(textSize) {
				this.displayedText = this.info?.paragraph.slice(0, textSize);
				this.isCollapsed = !this.isCollapsed;
			},
			expandText() {
				this.displayedText = this.info?.paragraph;
				this.isCollapsed = !this.isCollapsed;
			}
		},
		created() {
			
		}
	};
	
</script>
