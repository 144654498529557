window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

 try {
 	window.Popper = require('popper.js').default;
 	window.$ = window.jQuery = require('jquery');

    //require('bootstrap');
} catch (e) {}
// const WOW = require('wowjs');
// window.wow = new WOW.WOW({
//     live: false
// });
// window.wow.init();
// AOS
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
window.aos = require('aos');
aos.init();

// //light gallery
// require(['lightgallery'], function() {
//     require(["lg-zoom", "lg-thumbnail", "lg-share"], function(){
//         $("#lightgallery").lightGallery({
//         	// nextHtml:'<i class="fas fa-chevron-right"></i>',
//         	// prevHtml:'<i class="fas fa-chevron-left"></i>',
//         	selector:'.gallery-page .lightselect',
//         	subHtmlSelectorRelative: true,
//         	showAfterLoad:true
//         }); 
//     });
// });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

 window.axios = require('axios');

 window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
