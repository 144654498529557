<template>
	<div class="contact-map">
		<LMap :zoom="zoom" :center="center">
			<LTileLayer :url="url"></LTileLayer>
			<div v-for="branch in branches">
				<LMarker :lat-lng="[branch.latitude, branch.longitude]">
					<l-popup>
						<h4 class="has-text-red font-weight-bold is-size-5">{{ branch.name }}</h4>
					</l-popup>
				</LMarker>
			</div>
		</LMap>
	</div>

</template>
<script>
	import { LPopup, LMap, LTileLayer, LMarker } from "vue2-leaflet";
	export default {
		name:"MapAdress",
		components: {
			LMap,
			LTileLayer,
			LMarker,
			LPopup
		},
		props: {
			branches: {
				required: true
			},
		},
		data() {
			return {
				url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
				zoom: 5,
				center: [25.168282, 55.250286],
				bounds: null
			};
		}
	};
</script>
<style type="text/css">
	.contact-map{
		height: 500px;
		border-radius: 15px;
		overflow:hidden;
		z-index: 1;
		position: relative;
	}
</style>