<template>
  <countTo :startVal='startValue' :endVal='endValue' :duration='6000'></countTo>
</template>
 
<script>
  import countTo from 'vue-count-to';
  export default {
    components: { countTo },
    props:{
      startValue:{
        type:Number,
        default:0,
      },
      endValue:{
        required:true,
        type:Number
      },
    },
    data () {
      return {
        startVal: this.startVal,
        endVal: this.endVal,
        autoplay:false
      }
    }
  }
</script>