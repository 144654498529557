<template>
  <div dir="ltr">
    <slick
    ref="slick" class="projects-slick"
    :options="slickOptions">
    <slot></slot>
  </slick>
</div>
</template>

<script>
 import LazyLoad from '../GeneralComponents/LazyLoad';
 import Slick from 'vue-slick';
 export default {
  name: "projects",
  components: { Slick, LazyLoad },

  data() {
    return {
      slickOptions: {
        slidesToShow: 4,
        draggable: true,
        autoplay: true,
        infinite: false,
        dots: false,
        arrows: true,
        dotsClass:'primary-dots transparent-bg-dots slick-dots',
        nextArrow: `<button class="button next-btn has-text-white" aria-label="Next" type="button">
        <i class="fa-solid fa-chevron-left"></i>
        </button>`,
        prevArrow: `<button class="button prev-btn has-text-white" aria-label="Prev" type="button">
        <i class="fa-solid fa-chevron-right"></i>
        </button>`,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 522,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 421,
            settings: {
              slidesToShow: 2,
            }
          },
          ]

      },
    };
  },
  methods: {

  },
}
</script>
<style>

</style>
