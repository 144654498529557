<template>
    <div class="column is-3">
        <div class="card" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="linear">
            <div class="card-hover"></div>
            <div class="card-image">
                <a href="/show-project">
                    <figure class="image">
                        <lazy-load
                        src="/front/images/spinner.svg"
                        :lazy-src="info.image"
                        :lazy-srcset="info.image"
                        background-color="transparent"
                        :alt="info.name"
                        class="image-cover"
                        />
                    </figure>
                </a>
                <div class="is-overlay has-text-centered">
                    <button @click.prevent="openModal()" class="button is-transparent has-text-white">
                        <i class="fa-solid fa-magnifying-glass-plus is-size-2"></i>
                    </button>
                </div>
            </div>
            <div class="card-content px-2 py-4 has-text-primary">
                <h4 class="mt-1 is-size-4 has-text-centered">{{info.name}}</h4>
            </div>
        </div>
        <div :class="{'modal' : true ,  'is-active' : isActive}">
            <div class="modal-background" @click.prevent="close"></div>
            <div class="modal-card">
                <header class="modal-card-head has-background-white">
                    <span class="delete has-text-blue" aria-label="close" @click="close"></span>
                </header>
                <section class="modal-card-body pt-1">
                 <div class="card px-4">
                    <div class="card-image">
                        <figure class="image">
                            <lazy-load
                            src="/front/images/spinner.svg"
                            :lazy-src="info.image"
                            :lazy-srcset="info.image"
                            background-color="transparent"
                            :alt="info.name"
                            class="image-cover"
                            />
                        </figure>
                    </div>
                    <div class="card-content px-0 has-text-primary">
                        <h4 class="mt-1 is-size-2 font-weight-bold mb-2">{{info.name}}</h4>
                        <p>{{info.description}}</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

</template>

<script>
    import axios from 'axios';
    export default {
        name: 'ViewModal',
        data: () => ({
            isActive: false,
        }),
        props: {
            info: {
                type: Object,

            }
        },
        methods: {
            close(){
                this.isActive = false;
                this.item = [];
            },
            openModal() {
                this.isActive = true;
            },
        },
        watch: {
            'filters.brand_id': function(val){
                axios.get(`/dashboard/brands/${val}`)
                .then(({data}) => {
                    this.car_models = data;
                });
            }
        }
    };
</script>
<style type="text/css">

</style>
