<template>
	<div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="4">
			<div class="columns is-multiline mt-6">
			<div class="column is-6 p-4" v-for="item in items" :key="item.id">
				<div class="card radius-15" data-aos="zoom-in" data-aos-duration="1500" data-aos-easing="linear">
					<div class="card-content">
						<h3 class="is-size-3 has-text-primary font-weight-bold mb-3">{{  item.name }}</h3>
						<p>{{ item.description }}</p>
						<ul class="mt-3">
							<li v-for="point in item.service_points">{{ point.service_point }}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		</div>
		<div class="has-text-centered mt-6" v-show="loadMore">
			<a rel="noreferrer" class="news-loader" href="">
				<img src="/front/images/loader.svg" alt="">
			</a>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	import LazyLoad from '../GeneralComponents/LazyLoad';
	export default {
		name: 'Services',
		components: {
			LazyLoad ,
		},
		directives: {
			infiniteScroll
		},
		props: {
			data: {
				type: Array,
				default: () => []
			},
			url: String,
		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		mounted() {
		},
		data: () => ({
			items: [],
			order : [],
			currentPage: 0,
			fetching: false,
			nextUrl : String,
			loadMore : true,
			isActive: false
		}),
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.items.push(...data.data);
						if(data.links.next != null) {
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
		created() {
			this.nextUrl = this.url;
			this.items = [];
			this.loadNextPage();
		}
	};
</script>
