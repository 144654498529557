/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 window.Vue = require('vue');
 import Vue from 'vue';
 import'./bulma';

 import'./script';

 /*---------General Components--------*/
 import Slick from 'vue-slick';
 import SlickAnimation from './components/GeneralComponents/slickCarouselAnimation';
 import LazyLoad from './components/GeneralComponents/LazyLoad';
 import SlotForm from './components/GeneralComponents/SlotForm';
 import VeeValidate from 'vee-validate';
 import VueTranslate from 'vue-translate-plugin';
 import Alert from './components/GeneralComponents/Alert';
 // import { Tabs, TabPane } from 'vue-bulma-tabs'
 import VueLazyload from 'vue-lazyload'
 import VueSweetalert2 from 'vue-sweetalert2'
 // import * as VueGoogleMaps from 'vue2-google-maps';
 // import *as VueGoogleMaps from "@vue-leaflet/vue-leaflet";
 // import Pagination from 'vue-pagination-2';

 Vue.component('lazy-load', LazyLoad);

 Vue.use(VueLazyload, {
 	preLoad: 100,
 	error: '',
 	loading: '/front/img/spinner.svg',
 	attempt: 1,
 	adapter: {
 		loading (listender, Init) {
 			listender.el.style.height = "100px";
 		},
 		loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
 			el.style.height= "auto";
 		},
 	}
 })

// Vue.component('pagination', Pagination);
Vue.use(VeeValidate);
Vue.use(VueTranslate);
// Vue.use(VueGoogleMaps, {
// 	load: {
// 		key: "AIzaSyCphqOpGEPztIh5Mwuj-sOSyGXlyJatl1s",
//     	libraries: "places" // necessary for places input
//     }
// });
Vue.use(VueSweetalert2);

/*-----------Site Components---------*/
import Sponsers from './components/SiteComponents/Sponsers';
import Projects from './components/SiteComponents/Projects';
import Counter from './components/SiteComponents/Counter';
import Services from './components/SiteComponents/Services';
import Clients from './components/SiteComponents/Clients';
import MapAdress from './components/SiteComponents/MapAdress';
import ProjectsPage from './components/SiteComponents/ProjectsPage';
import ProductsPage from './components/SiteComponents/products/ProductsPage';
import SingleProduct from './components/SiteComponents/products/SingleProduct';
import Documents from './components/SiteComponents/Documents';
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export {
	Slick,
	Sponsers,
	Projects,
	Counter,
	SlickAnimation,
	Services,
	Clients,
	SlotForm,
	Alert,
	VeeValidate,
	VueTranslate,
	MapAdress,
	ProjectsPage,
	ProductsPage,
	SingleProduct,
	Documents
}
const app = new Vue({
	el: '#app',
	components: {
 		// Tabs,
 		// TabPane,
 		Slick,
 		Sponsers,
 		Projects,
 		Counter,
 		SlickAnimation,
 		Services,
 		Clients,
 		SlotForm,
 		Alert,
 		VeeValidate,
 		VueTranslate,
 		MapAdress,
 		ProjectsPage,
 		ProductsPage,
 		SingleProduct,
 		Documents
 	},

 });
