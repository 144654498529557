<template>
	<div>
		<h2 class="has-text-centered font-weight-bold has-text-primary is-size-2 mb-4 mt-5">Filter <span class="has-text-secondary">The Projects</span></h2>
		<div class="button-group filter-buttons has-text-centered mb-6 mt-6" data-aos="zoom-in" data-aos-duration="1500" data-aos-easing="linear">
			<button v-for="type in types" class="button btn-transparent hvr-danger is-size-5 has-text-primary has-text-weight-bold mr-3 ml-3" @click="changeType(type)" :class="[activeType==type ? 'is-checked' : '']">
				{{type.name}}
			</button>
		</div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
			<div class="columns is-multiline">
				<div class="column is-3" v-for="item in items" :key="item.id">
					<div class="card" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="linear">
						<div class="card-hover"></div>
						<div class="card-image">
							<a :href="`/projects/${item.slug}`">
								<figure class="image">
									<lazy-load
									src="/front/images/spinner.svg"
									:lazy-src="item.image"
									:lazy-srcset="item.image"
									background-color="transparent"
									:alt="item.name"
									class="image-cover"
									/>
								</figure>
							</a>
						</div>
						<div class="card-content px-2 py-4 has-text-primary">
							<p class="is-tr-1 has-text-weight-normal">
								<span v-if="item.location" class="is-size-6 position-relative">{{  item.location }}</span>
							</p>
							<a :href="`/projects/${item.slug}`" class="has-text-primary">
								<h5 class="font-weight-bold is-size-5 mt-2">{{  item.name }}</h5>
							</a>
							<p class="has-text-weight-normal is-size-6 mt-2">{{ item.description }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="has-text-centered mt-6" v-show="loadMore">
				<a rel="noreferrer" class="news-loader" href="">
					<img src="/front/images/loader.svg" alt="">
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	import LazyLoad from '../GeneralComponents/LazyLoad';
	// import Pagination from 'vue-pagination-2';
	export default {
		name: 'ProjectsPage',
		component:'LazyLoad',
		directives: {
			infiniteScroll
		},
		props: {
			url: String,
			types:{
				type:Array,
				required:true
			}
		},
		data() {
			return {
				items: [],
				order : [],
				loaded:false,
				currentPage: 0,
				fetching: false,
				nextUrl : String,
				loadMore : true,
				isActive: false,
				activeType:null,
				isLoading: false
			}
		},
		mounted() {

		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					this.loaded =false;
					if(!this.nextUrl.includes('page')){
						this.isLoading = true;
					}
					else{
						this.isLoading = false;
					}
					axios.get(this.nextUrl).then(({ data }) => {
						this.items.push(...data.data);
						this.loaded =true;
						this.isLoading = false;
						if(data.links.next != null) {
							this.nextUrl = data.links.next;
						} else {
							this.nextUrl = data.links.next;
						}
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			},
			changeType(type){
				this.activeType = type;
				this.items = [];
				this.nextUrl = type.url;
				this.loadNextPage();
			}
		},
		created() {
			this.activeType = this.types[0];
			this.nextUrl = this.activeType.url;
			this.items = [];
			this.loadNextPage();
			this.loaded =true;
		}
	};
</script>

<style lang="scss">

</style>


